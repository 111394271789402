<template>
    <div class="flex flex-col w-10/12 mx-auto">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Product</th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Buyer</th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Stripe Charge</th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                </tr>
              </thead>

              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="(txn, idx) in filterList" :key="idx">
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-lg text-gray-900">{{ txn.product }}</div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-lg text-gray-900">{{ txn.buyerID }}</div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap">
                    <div class="text-lg text-gray-900">${{ txn.amount/100 }}</div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <div class="text-lg text-gray-900">{{ txn.charge_id }}</div>
                  </td>
                  <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <div class="text-lg text-gray-900">{{ mixins.methods.relativeDate(txn.created )}}</div>
                  </td>
                </tr>
              </tbody>

            </table>
          </div>
        </div>
      </div>
    </div>

</template>
<script>
import axios from 'axios'
import mixins from '@/mixins'

export default{
  data(){
    return{
      txns: [],
      mixins: mixins
    }
  },
  methods:{
    getSales(){
      axios.get('https://skipsource.skylineexecutive.com/admin/sales'
      ).then((res)=>{
        console.log(res.data)
        this.txns = res.data.results
      }).catch((err) =>{
        console.log(err)
      })
    }
  },
  computed:{
    filterList(){
      let chrono_list = this.txns
      chrono_list.sort((a, b) => b.created - a.created)
       let full_user_list = [...chrono_list]
       let uniq = [...new Set(full_user_list)];
       return uniq
    }
  },
  created(){
    this.getSales()
  }
}
</script>
<style>

</style>
