<template>
  <div class="w-full">
    <div class="ml-5">
      <h1 class="font-bold text-2xl">Sales</h1>
    </div>

    <SaleHistory />
  </div>
</template>
<script>
import SaleHistory from '@/components/SaleHistory.vue'
export default{
  name: 'Sales',
  components:{
    SaleHistory
  }
}
</script>
